<template>
    <div class="class-box-v">
        <div class="class-item-v" v-for="(item,index) in list" :key="index" >
            <div class="con-item-v">
                <!-- img -->
                 <el-image
                    style="width: 100%; height: 100px"
                    :src="item.url"></el-image>

                        <div class="title">
                            {{item.title}}
                        </div>
                    <div class="con">
                        <div class="desc">
                            {{item.t_desc}}
                        </div>
                        <div class="desc views">
                            <el-tag :type="item.tag|getCourseType" size="mini">
                                {{item.tag}} 
                            </el-tag>
                            &nbsp; <i class="el-icon-view"></i>  {{item.views?item.views+'人已学习':item.views}} 
                        </div>
                        <div class="desc views flex">
                            <div>
                                {{item.date|dateFormat}}
                            </div>
                            <div v-if="hasRule">
                                <i class="el-icon-edit" @click="click(item)"></i>
                                <i class="el-icon-delete" @click="del(item)"></i>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!-- add -->
        <div class="class-item-v">
            <div class="con-item-v-add">
                <div class="add" @click="add" title="添加">
                    +
                </div>
            </div>
        </div>
        <!-- 添加 -->
        <el-drawer
        title="添加"
        :visible.sync="drawer"
        :direction="direction">
        <AddClass @add="addSuc"/>
        </el-drawer>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import AddClass from '@/components/AddClass.vue'
export default {
    components:{
        AddClass
    },
    props:{
        list:Array
    },
    data(){
        return{
            hasRule:checkRules(17),//修改员工权限
            direction: 'rtl',
            drawer:false
        }
    },
    methods:{
        addSuc(){
            this.drawer = false
            this.$emit("addSuc")
        },
        add(){
            this.drawer = true
        },
        //删除
        del(item){
            this.$confirm('确定要删除该课程吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                let params = {
                    method:"delCourse",
                    id:item.id
                }
                this.$ajax.post(this.API.api,params).then(({msg})=>{
                        this.$message({
                            type: 'success',
                            message: msg
                        });
                        this.$emit("addSuc")
                        this.loading = false
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
            
            })
        },
        click(item){
            this.$emit("click",item)
        }
    }
}
</script>
<style lang="less">
.class-box-v{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    cursor:pointer;
    .class-item-v{
        padding:25px;
    }
    .con-item-v-add{
        width:200px;
        min-height: 200px;
        line-height: 200px;
        vertical-align: middle;
        text-align: center;
    }
    .con-item-v:hover{
        box-shadow: 2px 5px 5px 5px rgba(0, 0, 0, 0.1);
    }
    .con-item-v{
        width:200px;
        border-radius: 10px;
        min-height: 100px;
        position:relative;
        box-shadow: 1px 3px 1px 1px rgba(0, 0, 0, 0.1);
        border:1px #DBDBDB solid;
        overflow: hidden;
        .title{
            position:absolute;
            text-align: left;
            left:10px;
            top:30px;
            color: #fff;
            text-shadow: 1px 1px #999;
        }
        .title::before{
            content:"";
            width:40px;
            height:3px;
            background: #fff;
            position:absolute;
            top:-5px;
        }
    }
    .con{
        padding:10px;
        .title{
            font-size:16px;
            line-height: 30px;
            border-bottom: 1px #FBFBFB solid;
        }
        .desc{
            color:#333;
            font-size: 14px;
            line-height: 20px;
        }
        .views{
            font-size: 12px;
            color:#999;
            line-height: 30px;
        }
        .flex{
            display: flex;
            i{
                margin:0 10px;
            }
            div{
                flex:1;
            }
        }
        .flex>div:last-child{
            text-align: right;
        }
        .flex:first-child{
            text-align: left;
        }
    }
    .add{
        width: 100%;
        text-align: center;
        font-size: 60px;
        cursor: pointer;
        color: #666;
    }
    .add:hover{
        color:#39F;
    }
}
</style>