<template>
    <div v-loading="loading">
        <ClassList :list="list" @click="click" @addSuc="getCourse"/>
        <!-- 编辑 -->
        <el-dialog title="" :visible.sync="show" :modal-append-to-body="false">
            <ClassEdit :item='item'/>
        </el-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import { Icon, Grid, GridItem,Field,Uploader,Cell } from 'vant';
Vue.use(Field);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Uploader);
Vue.use(Cell);
Vue.use(Icon);
import ClassList from '@/components/Class.vue'
import ClassEdit from '@/components/ClassEdit.vue'
export default {
    components:{
        ClassList,
        ClassEdit
    },
    data(){
        return{
            list:[],
            loading:false,
            show:false,
            item:{}
        }
    },
    created(){
        this.getCourse()
    },
    methods:{
        click(item){
            console.log(item)
            this.show = true
            this.item = item
        },
        //获取课程
        getCourse(){
            let params = {
                method:"getCourse"
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                        console.log(res)
                        this.loading = false
                        this.list = res.data.course
                    }).catch(err=>{
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        }
    }
}
</script>