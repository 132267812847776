<template>
    <div class="c-i-e">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules" v-loading="loading">
            <el-form-item label="名称" required prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="类型" required prop="type">
                <el-select v-model="form.type" placeholder="请选择类型">
                <el-option label="视频" value="video"></el-option>
                <el-option label="pdf文档" value="pdf"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="form.type=='video'?'视频url':'测试题'" required prop="url">
                <el-input v-model="form.url"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{form.id?'修改':'添加'}}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        data:{
            type:Object
        },
        index:{//当前长度
            type:[Number,String]
        },
        course_id:{
            type:[Number,String]
        },
        chapter_id:{
            type:[Number,String]
        },
        lastOrder:{
            //当前最后的order排序
            type:[Number,String]
        }
    },
    watch:{
        data:{
            handler(val){
                if(val.name){
                    this.form = val
                    //console.log(this.chapter_id,this.course_id,this.index)
                }else{
                    this.form={
                        name:"",
                        url:"",
                        type:""
                    }
                }
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        return{
            loading:false,
            form:{
                name:"",
                url:"",
                type:"video"
            },
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择类型', trigger: 'blur' }
                ],
                url: [
                    { required: true, message: '请输入数据', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let params = this.form 
                    params.method = "addCourseItem"
                    params.chapter_id = this.chapter_id
                    params.course_id = this.course_id
                    params.t_order = this.lastOrder+1
                    this.loading = true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(({msg})=>{
                            this.$message({
                                message: msg,
                                type: 'success'
                            })
                            this.loading = false
                            //刷新
                            this.$emit("add",{})
                    })
                    .catch(err=>{
                        this.$message({
                        message: err.msg||err,
                        type: 'warning'
                        })
                        this.loading = false
                    })
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>

</style>