<template>
    <div class="drag-box" ref="wrapper" :style="{height:startHeight+'px'||'',top:top+'px',opacity,'background-color':background}">
        <slot />
    </div>
</template>
<script>
export default {
    name:"drag",
    props:{
        background:{
            type:String,
            default:"#fff"
        },
        opacity:{
            type:String,
            default:"0.8"
        },
        time:{
            type:[Number,String]
        },
        index:{
            type:Number
        },
        current:{
            type:Number
        },
        num:{
            type:Number
        },
        nRight:{
            type:Boolean,
            default:true
        },
        startHeight:{
            type:[Number,String]
        }
    },
    data(){
        return{
            targetIndex:0,
            top:0,
            abs:0,
            height:0,
            remainder:0,//余数
            wrapperDom: null,
            headerDom: null,
            disX: 0,
            disY: 0,
            minLeft: 0,
            maxLeft: 0,
            minTop: 0,
            maxTop: 0,
            prevLeft: 0,
            prevTop: 0,
        }
    },
    watch:{
        time:{
            handler(){
                this.$nextTick(()=>{
                    this.initDrag()
                })
            },
            deep:true,
            immediate:true
        },
        startHeight:{
            handler(val){
                 this.$nextTick(()=>{
                    if(val){
                        console.log(val)
                        this.height = val
                    }
                 })
            },
                deep:true,
                immediate:true
        }
    },
    methods:{
         initDrag() {
            this.wrapperDom = this.$refs.wrapper;
            this.height = this.wrapperDom.offsetHeight
            this.top = this.current*this.height
            this.maxTop = (this.num-(this.current+1))*this.height
            console.log('maxHeight',this.maxTop)
            this.wrapperDom.addEventListener('mousedown', this.onMousedown, false);//点击头部区域拖拽
        },
        onMousedown(e) {
            this.disX = e.clientX;
            this.disY = e.clientY ;
        
            this.minLeft = this.wrapperDom.offsetLeft;
            this.minTop = this.wrapperDom.offsetTop;
        
            this.maxLeft =  this.nRight?0: window.innerWidth - this.minLeft - this.wrapperDom.offsetWidth;
            //this.maxTop = this.nRight?0: this.minTop + this.num * this.height//window.innerHeight - this.minTop - this.wrapperDom.offsetHeight;
            //console.log(this.minTop,this.maxTop)
            const { left, top } = getComputedStyle(this.wrapperDom, false);
            this.prevLeft = parseFloat(left);
            this.prevTop = parseFloat(top);
        
            document.addEventListener('mousemove', this.onMousemove, false);
            document.addEventListener('mouseup', this.onMouseup, false);
            document.body.style.userSelect = 'none'; //消除拖拽中选中文本干扰
        },
        onMousemove(e) {
            let left = e.clientX - this.disX;
            let top = e.clientY - this.disY;
        
            if (-left > this.minLeft) {
                left = -this.minLeft;
            } else if (left > this.maxLeft) {
                left = this.maxLeft;
            }
            if (-top > this.minTop) {
                top = -this.minTop;
            } else if (top > this.maxTop) {
                top = this.maxTop;
            }
            //计算拖动到哪个位置
            let absTop = top//Math.abs(top)
            this.remainder= Math.floor(absTop/this.height)
            this.abs = absTop%this.height
            //console.log(absTop%this.height,this.remainder)
            this.wrapperDom.style.left = this.prevLeft + left + 'px';
            this.wrapperDom.style.top = this.prevTop + top + 'px';

            this.getTarget()
            this.$emit('change',{index:this.index,current:this.current,targetIndex:this.targetIndex })
        },
        getTarget(){
            let half = Math.floor(this.height/2)
            ,remainder=Math.abs(this.remainder)
            ,current = this.current
            
            if(this.remainder<0){
                //上移动
                if(Math.abs(this.abs)>half){
                    remainder ++
                }
                //console.log('上移动',remainder)
                this.targetIndex  = current - remainder + 1
            }else{
                if(Math.abs(this.abs)>half){
                    remainder ++
                }
                this.targetIndex = current + remainder
            }
        },
        onMouseup() {
            document.removeEventListener('mousemove', this.onMousemove, false);
            document.removeEventListener('mouseup', this.onMouseup, false);
            document.body.style.userSelect = 'auto'; //恢复文本可选中
            //console.log(current)
            this.top = 0
            this.$emit("mouseUp",{index:this.index,current:this.current,targetIndex:this.targetIndex })
        },
    }
}
</script>
<style lang="less">
.drag-box{
    position: absolute;
    top:0;
    z-index: 1;
    width:100%;
    cursor:move;
    .van-cell{
        background:none
    }
}
</style>