<template>
    <div class="course-list">
        <div class="card" v-for="(item,index) in list" :key='index'>
            <div class="m-title">
                <div class="left">
                    {{'第'+(index+1)+'章 '+item.name}}
                </div>
                <div class="right">
                    <span class="modify" title="编辑章" @click="editChapter(index)"><i class="el-icon-edit"></i>编辑</span>
                    <span class="del" title="删除章" @click="delChapter(index)"><i class="el-icon-delete"></i>删除</span>
                    <span class="modify" title="添加课节"  @click="addCourseItem(index)">
                        <i class="el-icon-plus"></i>添加课节
                    </span>
                </div>
            </div>
            <div class="c-list">
                <van-cell  v-for="(it,i) in item.list" :key='i'>
                        <div class="slot-value" slot="title">
                            <div class="item-title"  :style="{color:current==index&&i==targetIndex?'#39f':'#333'}">
                                {{(index+1)+'.'+(i+1)+' '+it.name}}
                            </div>
                            <div class="edit-icon" title="点击编辑">
                                <van-icon name="edit" class="custom-icon"
                        @click="edit(index,i)"/>
                            </div>
                            <div class="edit-icon" title="添加测试题" :class="it.test_id?'focus':''" >
                                <i class="el-icon-document-checked" @click="addTest(index,i)"></i>
                            </div>
                            <div class="edit-icon" title="删除该课节">
                                <i class="el-icon-delete" @click="DelCourseItem(it.id)"></i>
                            </div>
                        </div>
                        <div class="rotate" slot="right-icon" title="点击排序">
                            <van-icon name="exchange" class="custom-icon"
                        @click="click($event,index,i)"/>
                        </div>
                </van-cell>
                <Drag :num="item.list.length" :index="index" :current="itemIndex" :time="time"
                 v-if="show&&current==index" @mouseUp="mouseUp" @change="change" border>
                    <van-cell  :title="(current+1)+'.'+(itemIndex+1)+' '+data.name||'拖动'"/>
                </Drag>
                <!-- 添加 -->
                    <!-- <div class="btn-box">
                        <i class="el-icon-plus addCourseItem" title="添加课节" @click="addCourseItem(index)"></i>                
                    </div> -->
            </div>
        </div>
        <div class="btn-box"  @click="editChapter()">
             <i class="el-icon-plus addCourseItem" title="添加章"></i>  
             添加章              
        </div>
        <!-- 添加 -->
        <el-dialog append-to-body :title="currentItem.name?'修改':'添加'" :visible.sync="showEdit">
            <CourseItemEdit @add="added" :data="currentItem" :index="current" :lastOrder="list[current]?list[current].list.length-1:0"
             :chapter_id="list[current]?list[current].id:''" :course_id="list[current]?list[current].course_id:''"/>
        </el-dialog>
        <!-- 选择 -->
        <el-dialog append-to-body title="选择课节测试题" :visible.sync="showChooseTest">
            <CourseChoose @select="selectTest" @cancel="cancelTest"/>
        </el-dialog>
    </div>
</template>
<script>
import CourseItemEdit from '@/components/CourseItemEdit.vue'
import CourseChoose from '@/components/CourseChoose.vue'
import Drag from '@/components/Drag.vue'
export default {
    components:{
        Drag,
        CourseItemEdit,
        CourseChoose
    },
    props:{
        datalist:{
            type:Array
        }
    },
    watch:{
        datalist:{
            handler(val){
                this.list = val
                //console.log(val)
            },
            deep:true,
            immediate:true
        }
    },
    data(){
        return{
            showChooseTest:false,//显示选择课题
            list:[],//数据列表
            showEdit:false,//显示课节编辑
            time:0,
            show:false,
            current:0,
            itemIndex:0,
            targetIndex:null,
            data:{
                name:""
            },
            currentItem:{},//要编辑的课节
        }
    },
    methods:{
        cancelTest(){
            this.list[this.current].list[this.itemIndex].test_id = ""
            this.showChooseTest = false
            this.update()
        },
        //选择测试题
        selectTest(id){
            this.list[this.current].list[this.itemIndex].test_id = id
            this.showChooseTest = false
            //console.log(this.list[this.current].list[this.itemIndex])
            this.update()
        },
        update(){
            //更新
            let params = this.list[this.current].list[this.itemIndex] 
            params.method = "addCourseItem"
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({msg})=>{
                            this.$message({
                                message: msg,
                                type: 'success'
                            })
                            this.loading = false
                            //刷新
                            this.$emit("add",{})
                    })
                    .catch(err=>{
                        console.log(err.data)
                        this.$message({
                        message: err.msg||err,
                        type: 'warning'
                        })
                        this.loading = false
                    })
        },
        //添加课程测试题
        addTest(index,i){
            //this.$emit("addTest",id)
            this.itemIndex = i
            this.current = index
            this.showChooseTest = true
        },
        //编辑章
        editChapter(index){
            let defaultValue = index!=null? this.list[index].name:''
                this.$prompt('请输入章名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue:defaultValue
                }).then(({ value }) => {
                    if(value.length>=1&&value.length<=20){
                        let params = {
                            method:"addChapter",
                            name:value,
                            id:index!=null?this.list[index].id:'',
                            t_order:this.list.length
                        }
                        
                        this.$emit("addChapter",params)
                    }else{
                        this.$message({
                            message: "字符长度保持1～20之间！",
                            type: 'warning'
                        })
                    }
                }).catch(() => {    
                });
        },
        //删除章
        delChapter(index){
            if(this.list[index].list.length>=1){
                this.$message({
                    message: "该章下有课节，不能删除！",
                    type: 'warning'
                 })
                return
            }
            this.$confirm('确定要删除该章吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                let params = {
                    method:"delChapter",
                    id:this.list[index].id
                }
                    this.$ajax.post(this.API.api,params).then(({msg})=>{
                            this.$message({
                                message: msg,
                                type: 'success'
                            })
                            this.loading = false
                            //刷新
                            this.$emit("change",{})
                    })
                    .catch(err=>{
                        this.$message({
                        message: err.msg||err,
                        type: 'warning'
                        })
                        this.loading = false
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //添加成功
        added(){
            this.showEdit=false 
            this.currentItem = {}
            //刷新
            this.$emit("change",{})
        },
        //删除课节
        DelCourseItem(id){
            let params = {
                method:"delCourseItem",
                id
            }
            this.$confirm('确定删除该课节吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$ajax.post(this.API.api,params).then(({msg})=>{
                            this.$message({
                                message: msg,
                                type: 'success'
                            })
                            this.loading = false
                            //刷新
                            this.$emit("change",{})
                    })
                    .catch(err=>{
                        this.$message({
                        message: err.msg||err,
                        type: 'warning'
                        })
                        this.loading = false
                    })
                }).catch(() => {     
                });
            
        },
        //添加课节
        addCourseItem(index){
            this.targetIndex = null
            this.currentItem = {}
            this.showEdit = true
            this.current = index
            this.show = false
        },
        //编辑
        edit(index,i){
            //console.log('---',index,i)
            this.targetIndex = null
            this.showEdit = true
            this.currentItem = this.list[index].list[i]
            this.current = index
            this.show = false
        },
        //更新顺序
        updateOrder(index){
            //console.log(JSON.stringify(this.list[index].list))
            let list = this.list[index].list,reList=[]
            let ids=[],orders=[]
            list.forEach((item,index) => {
                item.t_order=index+1
                reList.push(item)
                ids.push(item.id)
                orders.push(item.t_order)
            });
            // console.log(reList)
            // console.log(ids,orders)
            let params = {
                method:'updateCourseItemOrder',
                ids:JSON.stringify(ids),
                orders:JSON.stringify(orders)
            }
            this.$ajax.post(this.API.api,params).then(({msg,data})=>{
                console.log(data)
                                this.$message({
                                    message: msg,
                                    type: 'success'
                                })
                            })
                            .catch(err=>{
                                this.$message({
                                    message: err.msg||err,
                                    type: 'warning'
                                })
                                this.loading = false
                            })
        },
        //滑块位置改变凸显出要替换的dom
        change(e){
            //let {targetIndex} = e 
            this.targetIndex = e.targetIndex
        },
        mouseUp(e){
            this.show = false
            let {index,current,targetIndex} = e
            //console.log(current+'移动到'+targetIndex)
            let list = this.list[index].list
            let data = this.setSort(current,targetIndex,list)
            this.list[index].list = data
            //更新顺序
            if(this.targetIndex!=this.currentItem){
                this.updateOrder(index)
            }
            
        },
        //移动位置
        setSort(index,targetIndex,arr){
            // this.$message({
            //     message: '从'+index+(index>targetIndex?'上移':'下移')+'到'+targetIndex+'位置',
            //     type: 'warning'
            // })
            if(targetIndex<index){
                //上移动
                arr.splice(targetIndex,0,arr[index])
                arr.splice(index+1,1)
                //arr.splice(targetIndex<index?index+1:index,1)
            }else{
                const m = arr[index]
                arr.splice(index,1)
                arr.splice(targetIndex,0,m)
            }
            //console.log(JSON.stringify(arr))
            return arr
        },
        click(e,index,i){
            this.show = true 
            this.current = index
            this.itemIndex = i
            this.data = this.list[index].list[i]
            this.time = new Date().toString()
        }
    }
}
</script>
<style lang="less">

    .btn-box{
        cursor:pointer;
    }
    .btn-box:hover{
        color: #e96969;
    }
.card{
    margin:10px 8px;
    border-radius: 5px;
    box-shadow: 1px 2px 1px  #DBDBDB;
    position:relative;
     .focus i{
        color: #39f;
    }
    .addCourseItem{
        cursor:pointer;
        font-size: 1.4em;
    }
    .addCourseItem:hover{
        color:#e96969;
    }
    .slot-value{
        display: flex;
    }
    .edit-icon{
        color:#999;
        padding-left: 5px;
        cursor: pointer;
    }
    .m-title{
        text-align: left;
        color: #000;
        padding:10px 15px;
        background: #fff;
        display: flex;
        .left{
            flex:1
        }
        .right{
            font-size: 0.8em;
            color:#999;
            cursor: pointer;
            .modify,.del{
                margin-left: 10px;
            }
            .modify:hover{
                color:#e96969;
            }
            .del:hover{
                color: #e96969;
            }
        }
    }
    .c-list{
        position: relative;
    }
    .drag-box{
        .van-cell{

            box-shadow: 1px 2px 1px rgb(98, 100, 100);
            background: rgb(195, 231, 231)
        }
    }
    .rotate{
        transform: rotate(90deg);
    }
}
   
</style>