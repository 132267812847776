<template>
    <div class="c-l-box">
        <div class="edit-box" v-loading="loading">
            <div class="img-box">
                <el-upload
                    v-loading="loading"
                    class="avatar-uploader"
                    :on-success="uploadSuccess"
                    :data="jsonData"
                    :action="actionUrl"
                    :show-file-list="false">
                <i class="el-icon-upload2 white" title="更改封面"></i>
                </el-upload>
                <el-image
                style="width: 180px; height: 200px"
                fit="fill"
                :src="data.url"></el-image>
            </div>
            <div class="info-box">
                <div class="title">
                    <el-input @change="disabled=false" v-model="data.title" placeholder="请输入标题"></el-input>
                </div>
                <div class="t_desc">
                    <el-input @change="disabled=false" v-model="data.t_desc" placeholder="请输入描述"></el-input>
                </div>
                <div class="v-line class-type">
                    <el-select v-model="data.tag"  @change="disabled=false"
                    filterable placeholder="请选择课程类别">
                        <el-option
                        v-for="item in courseType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="v-line  group">
                    <el-select v-model="data.t_group" @change="disabled=false"
                    filterable placeholder="请选择课程分组">
                        <el-option
                        v-for="item in groupList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="v-line save">
                    <el-button type="primary" @click="save" :disabled="disabled">修改课程详情</el-button>
                </div>
            </div>
        </div>
        <div class="v-title">
            课程目录
        </div>
        <el-divider></el-divider>
        <div class="course-list">
            <CourseList :datalist="courseList" @change="getClassChapter" @addTest="addTest" @addChapter="addChapter"/>
        </div>
    </div>
</template>
<script>
import CourseList from '@/components/CourseList.vue'
export default {
    components:{
        CourseList
    },
    props:{
        item:{
            type:Object
        }
    },
    data(){
        return{
            showTest:false,//选择课节测试题
            disabled:true,
            data:{},
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            loading:false,
            courseType:[],
            groupList:[],
            courseList:[]
        }
    },
    watch:{
        item:{
            handler(val){
                this.data = val
                this.getClassChapter()
            },
            deep:true,
            immediate:true
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.actionUrl=this.actionUrl+'?token='+this.user.token
        this.getcourseType()
    },
    methods:{
        //添加课节测试题
        addTest(id){
            console.log(id)
            this.showTest = true
        },
        //添加章
        addChapter(params){
            params.course_id = this.data.id
            this.$ajax.post(this.API.api,params).then(({msg})=>{
                                this.$message({
                                    message: msg,
                                    type: 'success'
                                })
                                this.loading = false
                                //刷新
                                this.getClassChapter()
                        })
                        .catch(err=>{
                            this.$message({
                            message: err.msg||err,
                            type: 'warning'
                            })
                            this.loading = false
                        })
        },
        //获取章节
        getClassChapter(){
            let params = {
                method:"getClassChapter",
                id:this.data.id
            }
            //console.log(params)
            this.$ajax.post(this.API.api,params).then(({data})=>{
                                //console.log(data)
                                if(data.chapters){
                                    let list=[]
                                    data.chapters.forEach((item)=>{
                                        let items=[]
                                        data.items.forEach((it)=>{
                                            if(item.id==it.chapter_id){
                                                items.push(it)
                                            }
                                        })
                                        item.list=items
                                        list.push(item)
                                    })
                                    this.courseList = list
                                console.log(list)
                                }
                                this.loading=false
                            })
                            .catch(err=>{
                                this.$message({
                                    message: err.msg||err,
                                    type: 'warning'
                                })
                                this.loading = false
                            })
        },
        save(){
            let params = this.data 
            if(!params.url||!params.title||!params.tag||!params.t_group){
                this.$message({
                    message: '必要项目请补充完整',
                    type: 'warning'
                })
                return
            }
            params.method="updateCourse"
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({msg})=>{
                                console.log(msg)
                                this.$message({
                                    message: msg,
                                    type: 'success'
                                })
                                this.loading=false
                            })
                            .catch(err=>{
                                this.$message({
                                    message: err.msg||err,
                                    type: 'warning'
                                })
                                this.loading = false
                            })
        },
        //图片上传成功
        uploadSuccess(response){
            //console.log(response)
            this.loading = false
            if(response.status==0){
                this.data.url = this.API.apiUrl+response.data 
                this.disabled = false
            }else{
                this.$message({
                    message:response.msg,
                    type: 'warning'
                });
            }
        },
         //获取课程类别
        getcourseType(){
            let params = {
                method:'getCourseType'
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                       let options =[],groups=[]
                        if(data){
                            data.forEach(item=>{
                                if(item.t_type==0){
                                    options.push({
                                    value:item.name,
                                    label:item.name
                                })
                                }else{
                                    groups.push({
                                        value:item.name,
                                        label:item.name
                                    })
                                }
                                
                            })
                        }
                        this.courseType = options
                        this.groupList = groups
                    })
                    .catch(err=>{
                        this.$message({
                            message: err.msg||err,
                            type: 'warning'
                        })
                        this.loading = false
                    })
        }
    }
}
</script>
<style lang="less">
.course-list{
    background: #f8f8f8;
    padding:10px 5px;
}
.edit-box{
    display: flex;
    .img-box{
        position: relative;
        .white{
            color:#fff;
            cursor: pointer;
            position:absolute;
            top:20px;
            right:10px;
            font-size: 26px;
            text-shadow: 1px 2px #999;
            z-index: 1;
        }
    }
    .info-box{
        flex:1;
        text-align: left;
        padding:0px 15px;
        .title{
            text-align: left;
            font-size: 16px;
            color:#333;
            padding:5px 0;
        }
        .v-line{
            padding:6px 0;
        }
    }
    
}
.c-l-box{
    .v-title{
        font-size: 16px;
    }
}

</style>