<template>
<!-- 添加证件 -->
    <div class="add-cert">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="课程名称" required prop="title">
                <el-input v-model="form.title" :placeholder="this.CONST.INPUT_HINT_NAME"></el-input>
            </el-form-item>
            <el-form-item label="课程描述" required prop="t_desc">
                <el-input v-model="form.t_desc" :placeholder="this.CONST.INPUT_HINT_NAME"></el-input>
            </el-form-item>
            <el-form-item label="课程类别" required prop="tag">
                    <el-select v-model="form.tag" 
                    filterable placeholder="请选择课程类别">
                        <el-option
                        v-for="item in courseType"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            <el-form-item label="课程分组" required prop="t_group">
                    <el-select v-model="form.t_group" 
                    filterable placeholder="请选择课程分组">
                        <el-option
                        v-for="item in groupList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            <el-form-item label="封面图片" required prop="url">
               <!-- upload -->
                <el-upload
                    v-loading="loading"
                    class="avatar-uploader"
                    :on-error="uploadFail"
                    :before-upload="change"
                    :on-success="uploadSuccess"
                    :data="jsonData"
                    :action="actionUrl"
                    :show-file-list="false">
                    <img v-if="form.url" :src="form.url" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
               <!-- upload -->
            </el-form-item>
            <el-form-item >
                <el-button type="primary" @click="onSubmit">
                    提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loading:false,
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            form:{
                title:"",
                t_desc:"",
                t_group:"",
                tag:"",
                url:""
            },
            courseType:[],//课程类型
            groupList:[],//课程分组
            rules:{
                title: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { min: 2, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
                ],
                t_desc: [
                    { required: true, message: '请输入课程描述', trigger: 'blur' },
                    { min: 2, max: 15, message: '长度在 1 到 25 个字符', trigger: 'blur' }
                ],
                tag: [
                    { required: true, message: '请选择类型', trigger: 'blur' }
                ],
                t_group: [
                    { required: true, message: '请选择课程分组', trigger: 'blur' }
                ],
                url: [
                    { required: true, message: '请上传课程封面', trigger: 'blur' }
                ]
            }
        }
    },
    created(){
      this.user = JSON.parse(sessionStorage.getItem('user'))
      this.actionUrl=this.actionUrl+'?token='+this.user.token
      this.getcourseType()
    },
    methods:{
        //获取课程类别
        getcourseType(){
            let params = {
                method:'getCourseType'
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                       let options =[],groups=[]
                        if(data){
                            data.forEach(item=>{
                                if(item.t_type==0){
                                    options.push({
                                    value:item.name,
                                    label:item.name
                                })
                                }else{
                                    groups.push({
                                        value:item.name,
                                        label:item.name
                                    })
                                }
                                
                            })
                        }
                        this.courseType = options
                        this.groupList = groups
                    })
                    .catch(err=>{
                        this.$message({
                            message: err.msg||err,
                            type: 'warning'
                        })
                        this.loading = false
                    })
        },
        change(){
            this.loading=true
        },
        uploadFail(err){
            this.loading = false
            this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
            });
        },
        uploadSuccess(response){
            //console.log(response)
            this.loading = false
            if(response.status==0){
            //suc
            this.form.url = this.API.api+response.data 
            console.log(this.form)
            }else{
            this.$message({
                message:response.msg,
                type: 'warning'
            });
            }
        },
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                //console.log(this.form)
                if (valid) {
                     let params = this.form 
                     params.method="addCourse"
                     this.loading = true
                    this.$ajax.post(this.API.api,params).then(({msg})=>{
                                console.log(msg)
                                this.$message({
                                    message: msg,
                                    type: 'success'
                                })
                                this.loading = false
                                this.$refs['form'].resetFields()
                                this.$emit("add",{})
                            })
                            .catch(err=>{
                                this.$message({
                                    message: err.msg||err,
                                    type: 'warning'
                                })
                                this.loading = false
                            })
                        }
                    })
            
        }
    }
}
</script>
<style lang="less">
.add-cert{
    padding: 10px ;

    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>